<template>
  <div class="executives-wrapper">
    <Permission/>
    <Breadcrumb/>
    <div class="title">Directors, Officers & Signatories</div>
    <div class="subtitle">{{corporationName}}</div>
    <Tabs/>
    <section>
      <Form
        ref="executiveForm"
        v-for="(executive, index) in executives"
        :key="executive.id"
        :index="index + 1"
        :executive="executive"
        :canUpdate="canUpdate"
        :canDelete="canDelete"
        @handleFlash="handleFlash"
      />
    </section>
    <section v-if="canCreate">
      <Form @handleFlash="handleFlash" />
    </section>
    <Flash title="Congrats" :content="flashContent" :show="showFlash" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Permission from '@views/addy_plus/base/Permission.vue'
import Tabs from '@views/addy_plus/corporations/Tabs.vue'
import Form from '@views/addy_plus/corporations/executives/Form.vue'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import { fetchCorporationExecutives } from '@api/addy-plus/corporations'
import Flash from '@components/modal/slide-down-flash.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'Corporation Directors, Officers & Signatories',
  mixins: [pageTitleMixin, corpDetailsMixin],
  components: {
    Permission,
    Tabs,
    Form,
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      showFlash: false,
      timer: null,
      flashContent: '',
      executives: [],
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    corporationId() {
      return this.$route.params.corporationId
    },
    canCreate() {
      return this.permissions.entityExecutives?.includes('create')
    },
    canUpdate() {
      return this.permissions.entityExecutives?.includes('update')
    },
    canDelete() {
      return this.permissions.entityExecutives?.includes('delete')
    },
  },
  created() {
    this.fetchExecutives()
  },
  methods: {
    fetchExecutives(callback) {
      fetchCorporationExecutives(this.corporationId).then((result) => {
        if (result.success) {
          this.executives = result.data
          callback && callback()
        }
      })
    },
    handleFlash(content) {
      this.fetchExecutives(() => {
        this.flashContent = content
        this.showFlash = true
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.hideFlash()
        }, 4000)
      })
    },
    hideFlash() {
      this.showFlash = false
    },
  },
}
</script>
<style lang="scss" scoped>
.executives-wrapper {
  .b-tabs:not(:last-child) {
    margin-bottom: unset;
  }
}
</style>
