<template>
  <div class="executive-form mb-5">
    <div class="card">
      <header class="card-header is-clickable" @click="showContent = !showContent">
        <p class="card-header-title is-size-4">{{ headerText }}</p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div v-show="showContent" class="card-content">
        <div class="field is-horizontal mb-0">
          <b-field label="First Name" custom-class="required" class="is-fullwidth">
            <b-input v-model="params.firstName" :disabled="!canUpdate"></b-input>
          </b-field>
          <b-field label="Middle Name" class="is-fullwidth mx-3">
            <b-input v-model="params.middleName" :disabled="!canUpdate"></b-input>
          </b-field>
          <b-field label="Last Name" custom-class="required" class="is-fullwidth">
            <b-input v-model="params.lastName" :disabled="!canUpdate"></b-input>
          </b-field>
        </div>
        <div class="field is-horizontal">
          <b-field label="Role" custom-class="required" class="is-fullwidth">
            <b-select placeholder="Select a role" v-model="params.role" :disabled="!canUpdate" @change="onRoleChange">
              <option
                  v-for="role in roles"
                  :value="role"
                  :key="role">
              {{ role }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Effective Date" custom-class="required" :class="`datepicker-${index} ml-3 is-fullwidth`">
            <b-datepicker
              editable
              v-model="params.effectiveDate"
              locale="en-CA"
              placeholder="YYYY-MM-DD"
              icon-right-clickable
              icon-right="close-circle"
              @icon-right-click="clearDate"
              :disabled="!canUpdate"
            ></b-datepicker>
          </b-field>
        </div>
        <!-- disabled until business decides what to do with Signing Authority -->
        <!-- <b-field
          label="Signing Authority Signature"
          class="mb-3 file-upload"
          :type="{ 'is-danger': uploadRequired }"
          :message="{ 'Signature upload file required': uploadRequired }"
          custom-class="required"
          v-if="signingAuthoritySelected"
        >
          <div class="field is-horizontal file-table-row is-flex is-align-items-center">
            <b-upload v-model="params.signingAuthoritiesSignature" class="file-table-row-upload mr-3">
              <a class="button is-small">
                <b-icon icon="upload"></b-icon>
                <span>{{ uploadButtonText }}</span>
              </a>
            </b-upload>
            <div
              class="file-table-row-filename is-clickable has-text-primary is-flex is-align-self-center"
              @click="onFilePreview(params.signingAuthoritiesSignature, params.signingAuthoritiesSignature.name)"
              v-if="hasSignatureUpload"
            >{{ params.signingAuthoritiesSignature.name }}</div>
          </div>
        </b-field> -->
        <b-field label="Delivery Address" custom-class="required" >
          <AddressForm
            ref="deliverAddressForm"
            :index="addressFormIndex"
            :address="params.deliveryAddress"
            defaultAddressType="delivery"
            :hasGoogleAutoComplete="true"
            :editable="canUpdate"
            @addressFormCompleted="(status) => { deliveryAddressFormCompleted = status }"
          ></AddressForm>
        </b-field>
        <div class="field">
          <label class="label required">Mailing Address</label>
          <b-checkbox
            v-model="params.sameDeliveryAndMailingAddress"
            type="is-blue"
            :disabled="!canUpdate"
          >Same as Delivery Address</b-checkbox>
          <AddressForm
            ref="mailingAddressForm"
            class="mt-2"
            :index="addressFormIndex + 1"
            :address="params.mailingAddress"
            defaultAddressType="mailing"
            :editable="canUpdate"
            :hasGoogleAutoComplete="true"
            @addressFormCompleted="(status) => { mailingAddressFormCompleted = status }"
            v-if="!params.sameDeliveryAndMailingAddress"
          ></AddressForm>
        </div>
        <div
          v-for="(files, key) in params.fileTypes"
          :key="key"
          class="field"
        >
          <div v-if="files.length > 0">
            <label class="label">{{ key }}</label>
            <div
              class="is-flex is-flex-direction-row is-align-items-center"
              v-for="(file, key) in files"
              :key="key"
            >
              <div
                class="file-table-row-filename is-clickable has-text-primary is-flex is-align-self-center my-2 mx-2"
                @click="onFilePreview(file, file.fileName)"
              >{{ file.fileName }}</div>
              <b-tooltip type="is-dark" multilined :active="showTooltip">
                <div
                  @mouseover="showTooltip = true"
                  @mouseout="showTooltip = false"
                  @click="toDataRoom(file.hashid)"
                >
                  <b-icon icon="external-link-alt" size="is-small" class="is-clickable" />
                </div>
                <template v-slot:content>
                  <div>
                    <b-icon icon="folder-open"></b-icon>
                    <span class="has-text-white">Open in Data Room</span>
                  </div>
                </template>
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="field" v-if="showUploadField">
          <label class="label">Upload Board Documents</label>
          <DropNUpload
            ref="dropNUpload"
            feature="corpExecutives"
            :fileCategories="fileCategories"
            :key="dropNUploadComponentKey"
            @onSubmit="onSubmit"
          ></DropNUpload>
          <b-progress
            :value="loadedPercentage"
            type="is-blue"
            format="percent"
            show-value
            v-show="showProgressBar"
          ></b-progress>
        </div>
        <b-button
          v-if="executive && canDelete"
          @click="deleteExecutive"
          class="is-danger has-text-white is-small-height has-text-weight-semibold mt-5 mr-3"
          :disabled="isLoading">Delete</b-button>
        <b-button
          v-if="canUpdate"
          @click="saveForm"
          class="has-background-blue is-small-height has-text-weight-semibold mt-5"
          :disabled="!formValid || isLoading">{{ actionText }}</b-button>
      </div>
    </div>
    <FilePreviewModal :fileUrl="previewUrl" :fileType="previewFileType" @closeModal="onFilePreviewModalHide"></FilePreviewModal>
  </div>
</template>
<script>
import FilePreviewModal from '@components/modal/file-preview-modal.vue'
import AddressForm from '@components/form/address-form'
import fileTypeMixin from '@utils/mixins/file-type-mixin.js'
import { createCorporationExecutive, updateCorporationExecutive, deleteCorporationExecutive, handleCorporateDataRoomFiles as handleCorpFile } from '@api/addy-plus/corporations'
import { camelToSnakeCase, encodeFileAsUrl } from '@utils/common-methods/common'
import { fetchDocUrl } from '@api/common'
import { defaultAddressObject } from '@utils/data/addy_plus_address_fields.js'
import DropNUpload from '@components/upload/drop-n-upload.vue'

const ROLES = [
  'Officer',
  'Director',
  'Officer & Director',
  'Signing Authority',
  'Officer & Signing Authority',
  'Director & Signing Authority',
  'Officer, Director & Signing Authority'
]
const AddressKeys = [
  'streetNumber', 'route', 'locality', 'administrativeAreaLevel1',
  'country', 'postalCode', 'subpremise',
  'latitude', 'longitude'
]

export default {
  components: {
    FilePreviewModal,
    AddressForm,
    DropNUpload,
  },
  mixins: [fileTypeMixin],
  data() {
    return {
      showContent: false,
      isLoading: false,
      roles: Object.freeze(ROLES),
      addressKeys: Object.freeze(AddressKeys),
      params: {
        firstName: null,
        middleName: null,
        lastName: null,
        role: null,
        deliveryAddress: JSON.parse(JSON.stringify(defaultAddressObject)),
        mailingAddress: JSON.parse(JSON.stringify(defaultAddressObject)),
        sameDeliveryAndMailingAddress: false,
        signingAuthoritiesSignature: {},
        effectiveDate: null,
        fileTypes: {},
      },
      previewUrl: '',
      previewFileType: 'image',
      deliveryAddressFormCompleted: false,
      mailingAddressFormCompleted: false,
      dropNUploadComponentKey: 0,
      showProgressBar: false,
      loadedPercentage: 0,
      showTooltip: false,
    }
  },
  props: {
    executive: {
      type: Object,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.initializeData()
  },
  computed: {
    corporationId() {
      return this.$route.params.corporationId
    },
    formValid() {
      return this.params.firstName &&
        this.params.lastName &&
        this.params.role &&
        this.deliveryAddressFormCompleted &&
        (this.params.sameDeliveryAndMailingAddress || this.mailingAddressFormCompleted)
        // disabled until business decides what to do with Signing Authority
        // && !this.uploadRequired
    },
    headerText() {
      if (!this.executive) return 'Add new Director, Officer or Signatory'

      const { firstName, middleName, lastName, role } = this.executive
      const fullName = [firstName, middleName, lastName].filter((string) => string).join(' ')
      return `${fullName} - ${role}`
    },
    actionText() {
      const prefix = this.executive ? 'Update' : 'Add'
      return `${prefix} Person`
    },
    signingAuthoritySelected() {
      return this.params.role && this.params.role.includes('Signing Authority')
    },
    hasSignatureUpload() {
      return !!this.params.signingAuthoritiesSignature?.name
    },
    uploadButtonText() {
      return this.hasSignatureUpload ? 'Update signature' : 'Upload signature'
    },
    uploadRequired() {
      return this.signingAuthoritySelected && !this.hasSignatureUpload
    },
    datePickerRightIcon() {
      return document.querySelector(`.datepicker-${this.index} .datepicker .icon.is-right.is-clickable`)
    },
    showUploadField() {
      return this.personIsOfficer || this.personIsDirector
    },
    hasUnsavedDropNUpload() {
      return this.$refs.dropNUpload.hasUnsavedChanges
    },
    addressFormIndex() {
      return this.index * 2 + 1
    },
    personIsOfficer() {
      return this.params.role && this.params.role.includes('Officer')
    },
    personIsDirector() {
      return this.params.role && this.params.role.includes('Director')
    },
    fileCategories() {
      const categories = []
      if (this.personIsDirector) categories.push({ id: 'Consent(s) to act as Director', label: 'Consent(s) to act as Director' })
      if (this.personIsOfficer) categories.push({ id: 'Consent(s) to act as Officer', label: 'Consent(s) to act as Officer' })
      return categories
    },
  },
  watch: {
    executive: {
      handler(value) {
        this.initializeData()
      },
    },
  },
  mounted() {
    this.addDatePickerRightIconClickEvent()
  },
  beforeDestroy() {
    this.removeDatePickerRightIconClickEvent()
  },
  methods: {
    initializeData() {
      if (this.executive) {
        this.params = this.executive
        this.params.effectiveDate = new Date(this.executive.effectiveDate.replaceAll('-', '/'))
      }
      // index 0 is empty form
      if (this.index === 1) this.showContent = true
      else this.showContent = false
    },
    parseParams() {
      const formData = new FormData()
      for (const key in this.params) {
        if (Object.hasOwnProperty.call(this.params, key)) {
          // setup address nested attributes form data
          if (key === 'deliveryAddress' || key === 'mailingAddress') {
            const address = this.params[key]
            Object.keys(address).forEach((addressKey) => {
              // prevent null fields getting saved as String 'null' in formData
              const addressValue = address[addressKey] || ''
              formData.append(`${camelToSnakeCase(key)}_attributes[${camelToSnakeCase(addressKey)}]`, addressValue)
            })
          } else if (key !== 'signingAuthoritiesSignature' || this.params.signingAuthoritiesSignature instanceof File) {
          // prevent backend file deletion on update, don't append file formData if no frontend upload
            // prevent null fields getting saved as String 'null' in formData
            const value = this.params[key] || ''
            formData.append(camelToSnakeCase(key), value)
          }
        }
      }
      if (!this.signingAuthoritySelected) {
        formData.delete('signing_authorities_signature')
      }
      return formData
    },
    handleResult(result) {
      if (result.success) {
        this.$emit('handleFlash', result.data.message)
        if (!this.executive) this.clear()
      }
      this.isLoading = false
    },
    deleteExecutive() {
      if (this.executive) {
        this.isLoading = true
        deleteCorporationExecutive(this.corporationId, this.executive.id).then(this.handleResult)
      }
    },
    updateExecutive() {
      updateCorporationExecutive(this.corporationId, this.executive.id, this.parseParams()).then(this.handleResult)
    },
    createExecutive() {
      createCorporationExecutive(this.corporationId, this.parseParams()).then(this.handleResult)
      this.showContent = false
    },
    onRoleChange() {
      if (!this.signingAuthoritySelected) {
        this.params.signingAuthoritiesSignature = {}
      }
    },
    saveForm() {
      if (!this.formValid) return
      if (this.params.sameDeliveryAndMailingAddress) this.setupMailingAddress()
      this.isLoading = true
      if (this.hasUnsavedDropNUpload) this.$refs.dropNUpload.onSubmit()
      if (this.executive) this.updateExecutive()
      else this.createExecutive()
    },
    clear() {
      Object.keys(this.params).forEach((key) => {
        // variable needs to be inside the loop, or else both forms will share the same data
        const address = JSON.parse(JSON.stringify(defaultAddressObject))
        this.params[key] = ['deliveryAddress', 'mailingAddress'].includes(key) ? address : null
      })
      this.$refs.deliverAddressForm.isOpen = false
      if (this.$refs.mailingAddressForm) this.$refs.mailingAddressForm.isOpen = false
    },
    onFilePreview(file, name) {
      this.previewFileType = this.getFileTypeFromName(name)
      if (!['image', 'pdf'].includes(this.previewFileType)) {
        this.previewUrl = 'use_default' // previewUrl is only used to toggle the preview modal for doc or excel
        return
      }

      // preview upload not yet saved to database
      if (this.params.signingAuthoritiesSignature.size) {
        return encodeFileAsUrl(this.params.signingAuthoritiesSignature, (url) => {this.previewUrl = url})
      }

      const hashid = this.corporationId
      fetchDocUrl({
        hashid,
        model_name: 'Corporation',
        document_type: 'signing_authorities_signature',
      }).then((res) => {
        if (!res.success) return
        this.previewUrl = res.data.url
      })
    },
    onFilePreviewModalHide() {
      this.previewUrl = ''
    },
    setupMailingAddress() {
      this.addressKeys.forEach((key) => {
        this.params.mailingAddress[key] = this.params.deliveryAddress[key]
      })
    },
    removeDatePickerRightIconClickEvent() {
      if (this.datePickerRightIcon) {
        this.datePickerRightIcon.removeEventListener('click', this.clearDate)
      }
    },
    addDatePickerRightIconClickEvent() {
      this.datePickerRightIcon.addEventListener('click', this.clearDate)
    },
    clearDate() {
      if (!this.params.effectiveDate) return
      this.params.effectiveDate = null
    },
    onSubmit(formData) {
      this.showProgressBar = true
      formData.append('department', 'legal')
      handleCorpFile(this.corporationId, formData, this.onUploadProgress).then((res) => {
        if (!res.success) return
        this.dropNUploadComponentKey++
      }).finally(() => {
        this.showProgressBar = false
      })
    },
    onUploadProgress(progressEvent) {
      const { loaded, total } = progressEvent
      this.loadedPercentage = loaded / total * 100
    },
    toDataRoom(hashid) {
      const path = this.$route.path.replace('executives', 'dataRoom')
      window.open(`${location.origin}${path}?target=${hashid}`)
      this.showTooltip = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.executive-form {
  max-width: 900px;
  font-size: 16px;
  .card .card-header button {
    border: unset;
    background: unset;
  }
  .file-table-row {
    height: 40px;
    .file-table-row-filename {
      font-size: 16px;
      width: 100%;
      &.is-clickable:hover {
        filter: brightness(80%);
      }
    }
  }
  .field {
    ::v-deep .label.required::before {
      content: '* ';
      color: red;
    }
    ::v-deep .select {
      width: 100%;
      select {
        width: 100%;
      }
    }
    ::v-deep .icon.is-left .fa {
      color: var(--addy-blue-light-1);
    }
  }
  .b-tooltip.is-multiline {
    ::v-deep .icon {
      .fa-folder-open {
        color: #fff;
      }
      .fa-external-link-alt {
        color: $addyBlue;
      }
    }
  }
}
</style>
